import superagent from 'superagent'
import $ from 'jquery'

const postLoginRequest = (username, password) => {
  const body = {
    user_session: {
      login: username,
      password: password,
    },
  }

  return superagent
    .post('/user_sessions')
    .set('Content-Type', 'application/json')
    .set('Accept', 'application/json')
    .set('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))
    .send(body)
    .then((response) => {
      return response.body
    })
    .catch((err) => {
      return Promise.reject(err.response.text)
    })
}

export default {
  postLoginRequest: postLoginRequest,
}
