import React, { useRef, useEffect } from 'react'
import RaccButton from 'Shared/components/button'
import Spinner from 'Shared/components/spinner'
import PropTypes from 'prop-types'

const PasswordInputForm = (props) => {
  const { username, password, setPassword, reEnterUsername, display, loading } =
    props
  const passwordInput = useRef()
  let className
  if (!display) {
    className = 'ui-helper-hidden'
  }

  useEffect(() => {
    if (display) {
      passwordInput.current.focus()
    }
  }, [display])

  return (
    <div id='password_container' className={className}>
      {loading && <Spinner extraClass={'o-flexGrid u-justifyContentCenter'} />}
      <div className={'o-flexGrid u-alignItemsCenter'}>
        <input
          id='password_field'
          className='h-input u-width300 u-marginRight'
          placeholder='Password'
          value={password}
          type='password'
          ref={passwordInput}
          onChange={(e) => setPassword(e.target.value.trim())}
          autoComplete='current-password'
        />
        <RaccButton value='Login' id='login_submit' type='submit' />
      </div>
      <div>
        <a id='forgot_password' href='/forgot_password'>
          Forget Password?
        </a>
        <span> | </span>
        <a id='change_username' onClick={reEnterUsername}>
          Not {username}?
        </a>
      </div>
    </div>
  )
}

PasswordInputForm.propTypes = {
  username: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  setPassword: PropTypes.func.isRequired,
  reEnterUsername: PropTypes.func.isRequired,
  display: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
}

export default PasswordInputForm
