import React, { useState, useEffect } from 'react'
import UsernameInputForm from './username_input_form'
import PasswordInputForm from './password_input_form'
import BrowserIcons from './browser_icons'
import LoginApi from './login_post'
import useAlerts from 'Shared/custom_hooks/use_alerts'
import PropTypes from 'prop-types'
import alertTypes from 'Shared/racc_alert_message/constants/alert_message_types'
import ContentSection from 'Shared/components/content_section'

const ENTER_USERNAME_STEP = 'enter user name'
const ENTER_PASSWORD_STEP = 'enter password'

const Login = (props) => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [step, setStep] = useState(ENTER_USERNAME_STEP)
  const [loading, setLoading] = useState(false)
  const [setAlert, generateAlertMessage] = useAlerts()
  const { alertData } = props

  useEffect(() => {
    if (alertData.success && alertData.success.length > 0) {
      setAlert({ type: alertTypes.SUCCESS, message: alertData.success })
    } else if (alertData.notice && alertData.notice.length > 0) {
      setAlert({ type: alertTypes.NOTICE, message: alertData.notice })
    }
  }, [alertData.success, alertData.notice, setAlert])

  const authenticateLogin = (e) => {
    e.preventDefault()

    if (step === ENTER_USERNAME_STEP) {
      if (username.length === 0) {
        setAlert({
          type: alertTypes.ERROR,
          message: 'You did not provide any details for authentication.',
        })
      } else {
        setAlert({ type: '', message: '' })
        setStep(ENTER_PASSWORD_STEP)
      }
    } else {
      if (password.length === 0) {
        setAlert({
          type: alertTypes.ERROR,
          message: 'You did not provide any details for authentication.',
        })
      } else {
        setLoading(true)

        LoginApi.postLoginRequest(username, password)
          .then((res) => {
            setAlert({ type: alertTypes.SUCCESS, message: res.success })
            window.location.assign(res.location)
          })
          .catch((err) => {
            setLoading(false)
            const response = JSON.parse(err)
            setAlert({
              type: alertTypes.ERROR,
              message: response.error || response.errors,
            })
          })
      }
    }
  }

  const reEnterUsername = () => {
    setStep(ENTER_USERNAME_STEP)
    setUsername('')
    setPassword('')
    setAlert({ type: '', message: '' })
  }

  return (
    <div className='o-sectionWidth'>
      <ContentSection extraClasses='u-marginTop'>
        {generateAlertMessage()}
        <div className='o-flexGrid u-justifyContentCenter u-alignItemsCenter u-height250'>
          <form
            className='o-flexGrid__cell'
            onSubmit={(e) => {
              authenticateLogin(e)
            }}
          >
            <UsernameInputForm
              username={username}
              setUsername={setUsername}
              display={step === ENTER_USERNAME_STEP}
            />
            <PasswordInputForm
              reEnterUsername={reEnterUsername}
              username={username}
              password={password}
              setPassword={setPassword}
              display={step === ENTER_PASSWORD_STEP}
              loading={loading}
            />
          </form>
        </div>
        <BrowserIcons />
      </ContentSection>
    </div>
  )
}

Login.propTypes = {
  alertData: PropTypes.object.isRequired,
}

export default Login
